export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const COUNTRY_LIST = "COUNTRY_LIST";
export const STATE_LIST = "STATE_LIST";
export const INDUSTRIES_LIST = "INDUSTRIES_LIST";
export const COMPANY_LIST ="COMPANY_LIST";
export const  USERROLE_LIST ="USERROLE_LIST";