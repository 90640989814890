import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Image, Collapse } from "react-bootstrap-v5";
import { Scrollbars } from "react-custom-scrollbars";
import "./sidebar.scss";
import "./App.css";
function LeftSideBar() {
  const [openMaster, setOpenMaster] = useState(false);
  const [openQuestions, setOpenQuestions] = useState(false);
  const [openReports, setOpenReports] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [openAssessments, setOpenAssessments] = useState(false);

  return (
    <div className="sidebar shadow leftsidebar">
      <div class="d-flex flex-column h-100">
        <div class="logo-block text-center">
          {/* <Image src="../images/logo.png" className="top-logo" /> */}
        </div>

        <Scrollbars style={{ height: "100vh" }}>
          <div class="sidebar-links d-flex flex-column p-3">
            <Link to="/admin/dashboard">Dashboard</Link>
            <Link to="/admin/registerationData">Registeration Data</Link>

            {/* <Link
              onClick={() => setOpenMaster(!openMaster)}
              aria-controls="example-collapse-text"
              aria-expanded={openMaster}
              className="d-flex align-items-center"
              to="#"
            >
              Master
              <i class="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={openMaster}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to="/admin/category-listing">Categories</Link>
                  <Link to="/admin/subcategory-listing">Subcategory</Link>
                  <Link to="/admin/subject-listing">Subjects</Link>
                  <Link to="/admin/topic-listing">Topics</Link>
                  <Link to="/admin/skill-listing">Skills</Link>
                  <Link to="/admin/exam-subjects">Exam Subjects</Link>
                  <Link to="/admin/subject-topic">Exam Subject Topic</Link>
                  <Link to="/admin/unit-listing">Units</Link>
                  <Link to="/admin/concept-listing">Concepts</Link>
                  <Link to="/admin/manage-publishers">Publishers</Link>
                  <Link to="/admin/manage-institutions">Institutions</Link>
                  <Link to="/admin/exam">Exam</Link>
                </div>
              </div>
            </Collapse> */}
            {/* <Link
              onClick={() => setOpenQuestions(!openQuestions)}
              aria-controls="example-collapse-text"
              aria-expanded={openQuestions}
              className="d-flex align-items-center"
              to="#"
            >
              Questions
              <i class="fas fa-chevron-right right-arrow ms-auto"></i>
            </Link>
            <Collapse in={openQuestions}>
              <div>
                <div className="subMenu d-flex flex-column">
                  <Link to="/admin/question-listing">Manage Questions</Link>
                  <Link to="/admin/add-question">Add Question</Link>
                  <Link to="/admin/add-question-passage">Add Passage</Link>
                </div>
              </div>
            </Collapse> */}
          </div>
        </Scrollbars>

        <div class="mt-auto text-center LogoutBox">
          <Link to="/admin">Logout</Link>
        </div>
      </div>
    </div>
  );
}

export default LeftSideBar;
