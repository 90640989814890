import React, { useState } from "react";
import { Link } from "react-router-dom";
// import '../../assets/scss/style.scss';
import Aux from "../hoc/_Aux";
// import Breadcrumb from '../layout/AdminLayout/Breadcrumb';
import { site_ip } from "../globalSetting";
import axios from "axios";
import "./Admin.css";
import {
  Col,
  Row,
  Image,
  Form,
  InputGroup,
  Container,
  Card,
} from "react-bootstrap";
import AdminHeader from "./AdminHeader";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  //   useEffect(()=>{
  //       if(localStorage.getItem('quantam_admin_id') !== null){
  //         window.location.href = '/admin/dashboard';
  //       }
  //   }, []);

  const handleLogin = () => {
    if (!validateEmail(email.trim())) {
      setMessage("Please Enter Valid Credentials");
    } else if (password.trim() === "") {
      setMessage("Please Enter Valid Credentials");
    } else {
      axios({
        method: "post",
        url: site_ip + "/auth/adminSignin",
        data: {
          admin_email: email,
          password: password,
        },
        dataType: "json",
      })
        .then(function (response) {
          if (response.data.accessToken) {
            localStorage.setItem('user_id', response.data.content.admin_id);
            localStorage.setItem('role_id', response.data.content.role_id);
            localStorage.setItem('user_name', response.data.content.admin_name);
            redirectUser();
          } else {
            alert("Invalid credentials");
          }
        })
        .catch(function (error) {
          console.log("error1" + error);
        });
    }
  };

  const redirectUser = () => {
    setTimeout(() => {
      window.location.href = "/admin/dashboard";
    }, 1000);
  };

  return (
    <Aux>
      <div>
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="w-100" style={{ maxWidth: "400px" }}>
            <Card style={{border:"1px solid #883B80"}}>
              <Card.Body>
                <h2
                  className="text-center mb-4"
                  style={{
                    fontSize: "30px",
                    color: "black",
                    fontWeight: "Bold",
                  }}
                >
                  SIGN IN
                </h2>
                <Row>
                  <Col md={12}>
                    <div style={{ height: "auto" }}>
                      {message && (
                        <div className="form-group">
                          <div
                            className="alert alert-danger alert-dismissible"
                            role="alert"
                          >
                            {message}
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Form>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                      placeholder="Email Address *"
                      onChange={(e) => {
                        setMessage("");
                        setEmail(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="password"
                      aria-label="Password"
                      aria-describedby="basic-addon1"
                      placeholder="Password *"
                      onChange={(e) => {
                        setMessage("");
                        setPassword(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <div className="row">
                    <div className="col-md-7">
                      <input type="checkbox"/>
                      <label className="mr-1">Remember me</label>
                    </div>
                    <div className="col-md-5">
                      <Link to="/admin/forgot-password">
                        Forgot Password
                      </Link>
                    </div>
                  </div>
                  <div className="div-login">
                  <button
                    type="button"
                    className="btn-login"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </Aux>
  );
}

export default Login;
