import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { site_ip } from '../constants';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import '../App.css';
function Login() {
  const history = useHistory();
  const initialValues = { 
    salutation: "",
    first_name: "",
    last_name: "",
    user_email: "",
    user_mobile: "",
    city_id: "",
    state_id: "",
    country_id: 1,
    doc_final_degree: "",
    speciality_id: "",
    university_name: "",
    user_login: "",
    first_time_login_ind:'Y',
    full_access_ind: "",
    coupon_code: "",
    subject_id: "",
    zipcode: "",
    gender: "",
    publisher_id: "",
    institution_id: "",
    password: "",
    address:"",
    participating_as:"",
  };
  const [values, setValues] = useState(initialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "state_id"){
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
        city_id:""
      }));
      getCities(value)
    }if(name === "user_mobile"){
      if(validPositiveNumber(value)){
        setValues((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }else{
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() =>{
    getSpecilization();
    getStates();
  }, [])

  const [speciality, setSpeciality] = useState([])
  const getSpecilization = () => {
    axios({
      method: 'get',
      url: site_ip + 'getSpecialisation',
      dataType: 'json',
    })
      .then(function (response) {
        setSpeciality(response.data.Data)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [states, setStates] = useState([])
  const getStates = () => {
    axios({
      method: 'get',
      url: site_ip + 'getStatesByCountry/1',
      dataType: 'json',
    })
      .then(function (response) {
        setStates(response.data.Content)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const [cities, setCities] = useState([])
  const getCities = (id) => {
    axios({
      method: 'get',
      url: site_ip + 'getCities/'+ id,
      dataType: 'json',
    })
      .then(function (response) {
        setCities(response.data.Content)
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const validateEmail = (mail) => {
    if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            mail
        )
    ) {
        return true;
    }
    return false;
};

const validPositiveNumber = (number) => {
    if (number === "") {
        return true;
    }
    var positiveNumber = /^(0|\+?[1-9]\d*)$/;
    if (String(number).match(positiveNumber)) {
        return true;
    } else {
        return false;
    }
};

  const handleSubmit = () => {
    if(values?.first_name.trim() === ''){
      alert("Please enter your name.")
      return
    }else if(values?.doc_final_degree.trim() === ''){
      alert("Please enter your degree.")
      return
    }else if(!validateEmail(values?.user_email)){
      alert("Please enter a valid email address.")
      return
    }else if(values?.user_mobile?.length !== 10){
      alert("Please enter a valid mobile number.")
      return
    }else if(values?.speciality_id.trim() === ''){
      alert("Please select your specialty.")
      return
    }else if(values?.university_name.trim() === ''){
      alert("Please enter your University/Hospital/Clinic Name")
      return
    }else if(values?.address.trim() === ''){
      alert("Please enter your address.")
      return
    }else if(values?.state_id.trim() === ''){
      alert("Please select your state.")
      return
    }else if(values?.city_id.trim() === ''){
      alert("Please select your city.")
      return
    }else if(values?.participating_as.trim() === ''){
      alert("Please select participating as.")
      return
    }
    
    axios({
      method: 'post',
      url: site_ip + 'auth/signup',
      data: values,
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response)
        if (response.data.Status === 200) {
          history.push("/success")
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function (error) {
        alert("Email or Mobile number is already registered.")
      });
  };

  return (
    <div className="login-background">
      <Row style={{margin: 0}}>
        <Col md={3}></Col>
        <Col md={6}>
          <div className='form-box'>
            <img src="./images/proguide_Website_banner.jpg" />
            <div style={{height:"15px"}}></div>
            <p>Register Today for</p>
            <p>3D Printed Temporal Bone Dissection Workshop</p>
            <div style={{height:"15px"}}></div>
            <Row style={{margin: 0}}>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="first_name" value={values.first_name} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Degree <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="doc_final_degree" value={values.doc_final_degree} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Email <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="user_email" value={values.user_email} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Mobile No. <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="user_mobile" value={values.user_mobile} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Specialty <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Select name="speciality_id" onChange={handleChange} value={values.speciality_id}>
                    <option value="">-Select-</option>
                    {speciality?.map((item, index)=>{
                      return(
                        <option key={item?.doc_spec_id} value={item?.doc_spec_id}>{item?.specilisation}</option>    
                      )
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>University/Hospital/Clinic Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control type="text" placeholder="" name="university_name" value={values.university_name} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Address <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Control as="textarea" placeholder=""  name="address" value={values.address} onChange={handleChange}/>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>State <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Select name="state_id" onChange={handleChange} value={values.state_id}>
                    <option value="">-Select-</option>
                    {states?.map((item, index)=>{
                      return(
                        <option key={item?.state_id} value={item?.state_id}>{item?.state_name}</option>    
                      )
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>City <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Select name="city_id" onChange={handleChange} value={values.city_id}>
                    <option value="">-Select-</option>
                    {cities?.map((item, index)=>{
                      return(
                        <option key={item?.city_id} value={item?.city_id}>{item?.city_name}</option>    
                      )
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Participating as <span style={{color:"#ff0000"}}>*</span></Form.Label>
                  <Form.Select name="participating_as" onChange={handleChange} value={values.participating_as}>
                    <option value="">-Select-</option>
                    <option value="Dissector">Dissector</option>
                    <option value="Observer">Observer</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <div style={{display:"flex", alignItems:'center', justifyContent:'center'}}>
              <Button variant="success" onClick={() => handleSubmit()}>REGISTER NOW</Button>
            </div>
          </div>
        </Col>
        <Col md={3}></Col>
      </Row>
    </div>
  );
}

export default Login;
