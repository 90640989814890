import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { site_ip } from '../constants';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import '../App.css';
function SuccessPage() {
  const history = useHistory();

  return (
    <div className="login-background">
      <Row style={{margin: 0}}>
        <Col md={4}></Col>
        <Col md={4}>
          <div className='form-box'>
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <i className="fas fa-check-circle" style={{color:"#4caf50", fontSize:"120px"}}></i>
            </div>
            <div style={{height:"30px"}}></div>
            <p style={{fontSize:"30px", color:"#4caf50"}}>CONGRATULATIONS!</p>
            <p>Your details successfully submitted.</p>
            <div style={{height:"15px"}}></div>
          </div>
        </Col>
        <Col md={4}></Col>
      </Row>
    </div>
  );
}

export default SuccessPage;
