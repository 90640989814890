import { lazy } from "react";

const Dashboard = lazy(() => import("./content/Dashboard"));

const routes = [
  {
    path: "/admin/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  
  
];

export default routes;
