import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import RegistrationForm from "./screen/RegistrationForm";
import SuccessPage from "./screen/SuccessPage";
// Admin Pages
import Admin from "./admin/index";
import LeftSideBar from "./admin/LeftSideBar";
import Dashboard from "./admin/content/Dashboard";
import RegisterationData from "./admin/content/RegisterationData";

function App() {
  const RouteWithLayout = ({
    component: Component,
    layout: Layout,
    ...rest
  }) => (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />{" "}
        </Layout>
      )}
    />
  );

  // Admin Panel Layout
  const AdminLayout = ({ children }) => <>{children}</>;

  const LayoutOfAfterLogin = ({ children }) => (
    <>
      <LeftSideBar />
      <div className="right-block">
        <div className="content-wrapper"> {children}</div>
      </div>
    </>
  );

  return (
    <Router>
      <Switch>
        {/* Admin routes */}
        <RouteWithLayout
          exact
          path="/admin"
          layout={AdminLayout}
          component={Admin}
        />
        <RouteWithLayout
          exact
          path="/admin/dashboard"
          layout={LayoutOfAfterLogin}
          component={Dashboard}
        />
         <RouteWithLayout
          exact
          path="/admin/registerationData"
          layout={LayoutOfAfterLogin}
          component={RegisterationData}
        />
         <RouteWithLayout
          exact
          path="/registration"
          layout={AdminLayout}
          component={RegistrationForm}
        />
         <RouteWithLayout
          exact
          path="/success"
          layout={AdminLayout}
          component={SuccessPage}
        />
      </Switch>{" "}
    </Router>
  );
}

export default App;
