import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import LeftSideBar from "./LeftSideBar";
import Login from "./content/login";
import routes from "./routes";

const RouteWithLayout = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Suspense
          fallback={
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "100vh" }}
            >
              Loading...
            </div>
          }
        >
          <Component {...props} />
        </Suspense>
      </Layout>
    )}
  />
);

const BlankLayout = ({ children }) => <>{children}</>;

const LayoutOfAfterLogin = ({ children }) => (
  <>
    <LeftSideBar />
    <div className="right-block">
      <div className="content-wrapper"> {children}</div>
    </div>
  </>
);

function App() {
  return (
    <Switch>
      <RouteWithLayout
        exact
        path="/admin"
        layout={BlankLayout}
        component={Login}
      />
      {routes.map((route, i) => (
        <RouteWithLayout
          key={i}
          exact={route.exact}
          path={route.path}
          layout={LayoutOfAfterLogin}
          component={route.component}
        />
      ))}
    </Switch>
  );
}

export default App;
