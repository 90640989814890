import React, { useState, useEffect } from 'react';
import { site_ip } from '../globalSetting';
import './common.css';
function Dashboard() {
  const [comapanydata, getCompanyData] = useState([]);
  const fetchCompanyData = () =>
    fetch(`${site_ip}/adminDashboardStats`).then((res) => res.json());
  useEffect(() => {
    console.log("hello")
    fetchCompanyData().then((data) => getCompanyData(data));
  }, []);
  return (
    <>
      <div class="container-fluid containerLess">
        <div class="row containerWhite mx-2">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-1">
              <p className="box-heading">Books Count</p>
              <p className="box-value">{comapanydata?.booksCount}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-2">
              <p className="box-heading">Chapters Count</p>
              <p className="box-value">{comapanydata?.chaptersCount}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-3">
              <p className="box-heading">Journals Count</p>
              <p className="box-value">{comapanydata?.journalsCount}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <div className="dashboard-card box-4">
              <p className="box-heading">Total Articles</p>
              <p className="box-value">{comapanydata?.totalarticles}</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 mt-2">
            <div className="dashboard-card box-4">
              <p className="box-heading">Users Count</p>
              <p className="box-value">{comapanydata?.usersCount}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
