const config = {
  defaultPath: '/auth/signin',
  basename: '/admin', // only at build time to set, like /datta-able
  collapseMenu: false, // mini-menu
  navIconColor: false,
  navFixedLayout: true,
  boxLayout: false,
  layout6Background:
    'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
  layout6BackSize: '', // used only for pre-layout = layout-6
};
export default config;
