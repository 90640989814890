import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Table,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Aux from "../hoc/_Aux";
import axios from "axios";
import { site_ip } from "../globalSetting";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../customTableStyle";
import { useHistory } from "react-router-dom";

export default function RegisterationData(props) {
  const history = useHistory();
  const [data, setData] = useState([]);

  const headers = [
    { label: "Sr.No.", key: "index" },
    { label: "First Name", key: "first_name" },
    { label: "Email", key: "user_email" },
    { label: "Contact No.", key: "user_mobile" },
  ];

  const csvLink = {
    filename: "AllRegisterationData.csv",
    headers: headers,
    data: data,
  };

  const getRegisterationData = () => {
    axios
      .post(site_ip + "/getRegistrationData")
      .then((res) => {
        setData(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRegisterationData();
  }, []);

  const columns = [
    {
      name: "S. No.",
      width: "120px",
      selector: (row, index) => index + 1,
    },
    {
      name: "First Name",
      selector: (row) => row.first_name,
    },
    {
      name: "Email",
      selector: (row) => row.user_email,
    },
    {
      name: "Contact",
      selector: (row) => row.user_mobile,
    },
  ];

  return (
    <Aux>
      <br />
      <Container>
        <Row>
          <Col md={6} className="mb-3">
            <h4>Registeration Data</h4>
          </Col>
          <Col md={6} className="text-end mb-3">
            <CSVLink {...csvLink} className="btn btn-primary">
              Export
            </CSVLink>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              customStyles={tableCustomStyles}
              columns={columns}
              data={data}
              pagination
              dense
            />
          </Col>
        </Row>
      </Container>
    </Aux>
  );
}
